import * as prod from './prod';
import * as dev from './dev';

const subpathRegex = /[-_\w]+[.]\w+[.]\w+/gi;
const prodRegex = /^\w+[.]\w+$/gi;

let config: any;

/**
 * checks if file exists
 * @param path the file path to check
 * @returns the file or null
 */
const checkFileRequire = (path: string) => {
  try {
    return require(`${path}`);
  } catch (err) {
    return null;
  }
};

if (typeof window !== 'undefined') {
  const { hostname } = window.location;
  let env = hostname.substring(0, hostname.indexOf('.'));
  env = checkFileRequire(`./env/${env}`) ? env : 'beta';

  // sub domain envs; *.daba.store
  if (subpathRegex.test(hostname)) {
    // @ts-ignore
    config = require(`./${env}`);
  } else if (prodRegex.test(hostname)) {
    // production env; daba.store
    config = prod;
  } else {
    config = dev;
  }

  const restaurantAppUrl = window.location.protocol + "//" + hostname + "/backoffice";
  const serverUrl = window.location.protocol + "//" + hostname + "/live/api";

  config = {
    ...config,
    GATSBY_RESTAURANT_APP_URL: restaurantAppUrl,
    GATSBY_API_URL: serverUrl,
  };
}

console.log(config);

export default config;
